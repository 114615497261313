/* eslint  react/forbid-prop-types: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Tag from '../components/Tag';
import { headData } from '../data/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

const TagPage = ({ pageContext }) => {
  const { tag, projects } = pageContext;
  const { title, lang } = headData;

  const titleText = `${tag}というタグが付けられた音楽のページです | ${title}`;
  const description = `こちらは、${tag}というタグが付けられた音楽のページとなります。 | ${title}`;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{titleText}</title>
        <html lang={lang || 'en'} />
        <meta name="description" content={description} />
      </Helmet>
      <Tag tag={tag} projectsData={projects} />
    </>
  );
};

TagPage.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string,
    projects: PropTypes.array,
  }),
};

export default TagPage;
