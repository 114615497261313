/* eslint  react/forbid-prop-types: 0 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TagHero from './Hero/TagHero';
import Projects from './Projects/Projects';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';

import { PortfolioProvider } from '../context/context';

import { heroData, aboutData, contactData, footerData } from '../data/data';

function Tag({ tag, projectsData }) {
  const [hero, setHero] = useState({});
  const [about, setAbout] = useState({});
  const [projects, setProjects] = useState([]);
  const [contact, setContact] = useState({});
  const [footer, setFooter] = useState({});

  useEffect(() => {
    setHero({ ...heroData });
    setAbout({ ...aboutData });
    setProjects([...projectsData]);
    setContact({ ...contactData });
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ hero, about, projects, contact, footer }}>
      <TagHero tag={tag} />
      <Projects />
      <Contact />
      <Footer />
    </PortfolioProvider>
  );
}

Tag.propTypes = {
  tag: PropTypes.string,
  projectsData: PropTypes.array,
};

export default Tag;
